import { DraggableStateSnapshot } from 'react-beautiful-dnd';

// TODO cleanup or reuse.
// export const getCardStyle = (isDragging: boolean, draggableStyle: any): any => {
//     return {
//         ...draggableStyle,
//     };
// };

export const getCardBucketStyle = (
    isDraggingOver: boolean,
    nCards: number,
): any => ({
    background: isDraggingOver ? 'lightblue' : 'rgba(255, 255, 255, 0.2)',
    // flexBasis: nCards ? 1.25 + nCards * 5.65 + 'rem' : '100%', // See Card/style.scss
});

export const getCoinStyle = (
    style: any,
    snapshot: DraggableStateSnapshot,
): any => {
    if (!snapshot.isDragging) return {};

    if (!snapshot.isDropAnimating) {
        return {
            ...style,
        };
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: '0.001s',
    };
};

export const getCoinBucketStyle = (isDraggingOver: boolean): any => ({
    background: isDraggingOver ? 'lightblue' : 'rgba(255, 255, 255, 0.2)',
});

