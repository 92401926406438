import React, { ReactElement } from 'react';
import { Strings } from '../../strings';
import AppString from '../String';
import './style.scss';

const Header = ({
    onHome,
    title,
    dark = false,
}: {
    onHome: () => void;
    title?: Strings;
    dark?: boolean;
}): ReactElement => {
    const headerClass = dark
        ? 'Header Header--primary'
        : 'Header Header--white';

    const logoUrl = dark ? 'assets/img/logo-dark.png' : 'assets/img/logo.png';

    return (
        <div className={headerClass}>
            <span role="button" onClick={onHome}>
                <img
                    className="Header__logo"
                    src={logoUrl}
                    alt="Quantis Logo"
                />
            </span>
            {title && (
                <h2 className="Header__title text-tilting-bold">
                    <AppString k={title} />
                </h2>
            )}
            <div className="Header__home">
                <div role="button" className="Button--home" onClick={onHome} />
            </div>
        </div>
    );
};

export default Header;
