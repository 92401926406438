import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    AppActions,
    CardBucketIds,
    GameState,
} from '../../lib/types';
import AppString from '../String';
import { Strings } from '../../strings';
import './style.scss';

const GameControls = ({
    dispatch,
    gameState,
    onNext,
    onBack,
    bucketId,
}: {
    dispatch: Dispatch<AppAction>;
    gameState: GameState;
    onNext: () => void;
    onBack: () => void;
    bucketId?: CardBucketIds;
}): ReactElement => {
    const innerElement = (): ReactElement => {
        switch (gameState) {
            /** CARDS OPTIONS **/

            case GameState.CARDS_INIT:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_DRAG_CARD_BEGIN} />
                    </p>
                );
            case GameState.CARDS_MOVED:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_DRAG_CARD_MORE} />
                    </p>
                );
            case GameState.CARDS_ALL_MOVED:
                return (
                    <span
                        onClick={() =>
                            dispatch({
                                type: AppActions.CHECK_CARDS,
                            })
                        }
                        className="Button"
                        role="button"
                    >
                        <AppString k={Strings.GAME_CONTROLS_CHECK} />
                    </span>
                );
            case GameState.CARDS_CHECKED:
                return (
                    <p className="Button-half-height">
                        <AppString k={Strings.GAME_DRAG_CARD_RESULT} />
                    </p>
                );
            case GameState.CARDS_DONE:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_DRAG_CARD_RESULT_COMMENT} />
                    </p>
                );

            /** COINS OPTIONS **/

            case GameState.COINS_INIT:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_DRAG_COIN_BEGIN} />
                    </p>
                );
            case GameState.COINS_MOVED:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_DRAG_COIN_MORE} />
                    </p>
                );
            case GameState.COINS_ALL_MOVED:
                return (
                    <span
                        onClick={() =>
                            dispatch({
                                type: AppActions.CHECK_COINS,
                            })
                        }
                        className="Button"
                        role="button"
                    >
                        <AppString k={Strings.GAME_CONTROLS_CHECK} />
                    </span>
                );
            case GameState.COINS_CHECKED:
                return (
                    <p className="Button-half-height">
                        <AppString k={Strings.GAME_DRAG_COIN_RESULT} />
                    </p>
                );
            case GameState.COINS_DONE:
                return (
                    <p className="Button-half-height">
                        <AppString k={Strings.GAME_DRAG_COIN_RESULT_COMMENT} />
                    </p>
                );

            /** ANSWERS OPTIONS **/

            case GameState.QUESTION_INIT:
            case GameState.QUESTION_ANSWERED:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_QUESTIONS_INSTRUCTION} />
                    </p>
                );
            case GameState.QUESTION_ALL_ANSWERED:
                return (
                    <span
                        onClick={() =>
                            bucketId &&
                            dispatch({
                                type: AppActions.CHECK_QUESTIONS,
                                bucketId: bucketId,
                            })
                        }
                        className="Button"
                        role="button"
                    >
                        <AppString k={Strings.GAME_CONTROLS_CHECK} />
                    </span>
                );
            case GameState.QUESTION_CHECKED:
                return (
                    <p className="Button-height">
                        <AppString k={Strings.GAME_QUESTIONS_RESULT} />
                    </p>
                );

            case GameState.GAME_RECAP:
                return <p className="Button-height" />;

            default:
                return <p className="Button-height">&nbsp;</p>;
        }
    };

    return (
        <div className="GameControls">
            <div className="GameControls__prev">
                <span
                    role="button"
                    className="Button Button--prev"
                    onClick={onBack}
                >
                    &nbsp;
                </span>
            </div>
            <div className="GameControls__center">{innerElement()}</div>
            <div className="GameControls__next">
                {[
                    GameState.CARDS_CHECKED,
                    GameState.CARDS_DONE,
                    GameState.COINS_CHECKED,
                    GameState.COINS_DONE,
                    GameState.QUESTION_CHECKED,
                    GameState.QUESTION_DONE,
                ].includes(gameState) && (
                    <span
                        role="button"
                        className="Button Button--next"
                        onClick={onNext}
                    >
                        <AppString k={Strings.GAME_CONTROLS_NEXT} />
                    </span>
                )}
                {[GameState.GAME_RECAP].includes(gameState) && (
                    <span
                        role="button"
                        className="Button Button--next"
                        onClick={onNext}
                    >
                        <AppString k={Strings.GAME_RECAP_NEXT} />
                    </span>
                )}
            </div>
        </div>
    );
};

export default GameControls;
