import {
    CardBucketIds,
    CardBuckets,
    CardData,
    CoinBucketIds,
    CoinBuckets,
    CoinData,
    GameState,
    ModalData,
    OutroBoxData,
} from './lib/types';
import {
    cardBucketsWhere,
    coinBucketsWhere,
    cumulativeSum,
} from './lib/helpers';

// TODO fix with real data
export const initCardSrc: CardData[] = [
    {
        id: 'extraction',
        content: 'Raw material extraction',
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: 'Raw material extraction',
            content:
                'Electronic devices are made of many components, containing a variety of raw materials such as gold, magnesium, nickel, lithium, cobalt, etc. These raw materials need to be extracted from the earth, a process which is energy intensive.',
            image: 'extraction',
        },
    },
    {
        id: 'production',
        content: 'Production of the components',
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: 'Production of the components',
            content:
                'The components of eletronic devices (metals, plastics, glass, etc.) are produced from raw materials. The process to transform these materials into components typically requires the use of fossil fuels.',
            image: 'production',
        },
    },
    {
        id: 'assembly',
        content: 'Assembly of the device',
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: 'Assembly of the devices',
            content:
                'Once the components are produced, they are gathered in factories and assembled into a functioning device. There is energy required to power and heat the factories, which has a carbon impact.',
            image: 'assembly',
        },
    },
    {
        id: 'electricity-active',
        content: 'Electricity consumption (active)',
        allowedBuckets: [CardBucketIds.DEST_B],
        modalContent: {
            title: 'Active Electricity consumption',
            content:
                'Digital devices need eletricity to operate. The longer a device is used, the more eletricity is required to charge it.',
            image: 'electricity-active',
        },
    },
    {
        id: 'electricity-passive',
        content: 'Electricity consumption (passive)',
        allowedBuckets: [CardBucketIds.DEST_B],
        modalContent: {
            title: 'Passive Electricity consumption',
            content:
                'Digital devices consume electricity even when they are not in use, whether they are on "sleep mode" or switched off .',
            image: 'electricity-passive',
        },
    },
    {
        id: 'electricity-data',
        content: 'Electricity consumption',
        allowedBuckets: [CardBucketIds.DEST_D],
        modalContent: {
            title: 'Electricity consumption',
            content:
                'A data center makes our digital activites possible, for example, to provide access to a website, to collect and send e-mails, to store and consult databases, to manage e-commerce sites, or to create a sharing network. They are often active 24-hours and consume electricity continuously.',
            image: 'electricity-data',
        },
    },
    {
        id: 'distribution',
        content: 'Distribution of products from factories',
        allowedBuckets: [CardBucketIds.SRC],
        modalContent: {
            title: 'Distribution of products from factories',
            content:
                'The shipping of products is indeed a part of the life cycle, but the impact of this activity is very low as products are most often shipped in highly loaded container ships, resulting in a low relative carbon footprint per device.',
            image: 'distribution',
        },
    },
    {
        id: 'shopping',
        content: 'Shopping trip to buy a device',
        allowedBuckets: [CardBucketIds.SRC],
        modalContent: {
            title: 'Shopping trip to buy a device',
            content:
                'This includes the carbon footprint of a consumer traveling to the store to buy a device. While this is a factor, the relative impact of these shopping trips is insignificant.',
            image: 'shopping',
        },
    },
    {
        id: 'stores',
        content: 'Stores and franchises',
        allowedBuckets: [CardBucketIds.SRC],
        modalContent: {
            title: 'Stores and franchises',
            content:
                'Stores and franchises consume energy, but their carbon footprint is very low compared to the carbon emissions of digital devices.',
            image: 'stores',
        },
    },
    {
        id: 'electricity-networks',
        content: 'Electricity consumption',
        allowedBuckets: [CardBucketIds.DEST_C],
        modalContent: {
            title: 'Electricity consumption',
            content:
                'Communication networks (telecom operator networks, office networks and customer premises equipment) rely on electricity to operate. The ever-growing number of uses and users counter balance the gains in energy efficiency and increase the carbon emissions due to higher traffic levels.',
            image: 'electricity-networks',
        },
    },
];

export const initCardBuckets = {
    [CardBucketIds.SRC]: {
        cards: initCardSrc,
        maxCards: 10,
        isFull: true,
        questions: [],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {} as OutroBoxData,
    },
    [CardBucketIds.DEST_A]: {
        title: 'Device production',
        instruction: 'Drag here',
        cards: [],
        maxCards: 3,
        isFull: false,
        questions: [
            {
                text:
                    'Producing a smartphone emits %s times more carbon emissions than using it in Europe.',
                answers: ['2', '10', '30'],
                correctAnswer: '30',
                source: 'The Shift Project, 2019.',
            },
            {
                text:
                    'Increasing the lifetime of a laptop from 3 to 5 years decreases the carbon emissions by %s.',
                answers: ['5%', '28%', '37%'],
                correctAnswer: '37%',
                source: 'The Shift Project, 2019.',
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'expand-lifespan',
            modalData: {
                subtitle: 'Device production',
                title: 'Expand lifespan of the device',
                isListView: true,
                listItems: [
                    {
                        item:
                            'Avoid replacing your digital equipment too often.',
                        icon: 'mobile',
                    },
                    {
                        item:
                            'Maintain your digital devices and install protections against viruses and malware.',
                        icon: 'mask',
                    },
                    {
                        item:
                            'When not repairable, replace digital devices with refurbished products.',
                        icon: 'laptop',
                    },
                ],
            },
        } as OutroBoxData,
    },
    [CardBucketIds.DEST_B]: {
        title: 'Device usage',
        instruction: 'Drag here',
        cards: [],
        maxCards: 2,
        isFull: false,
        questions: [
            {
                text:
                    'Doing 100 Google searches on a laptop for one hour emits %s times more carbon than working offline on a laptop for one hour.',
                answers: ['1', '3', '10'],
                correctAnswer: '3',
                source: 'Quantis, Écobilan pour RTS (2020), Ecoinvent',
            },
            {
                text:
                    'A laptop on sleep mode emits at least %s less carbon emissions than on active mode (but still up to 100% more than a laptop on off mode).',
                answers: ['10%', '50%', '99%'],
                correctAnswer: '50%',
                source: 'Quantis, Écobilan pour RTS (2020), Ecoinvent',
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'limit-use',
            modalData: {
                subtitle: 'Device usage',
                title:
                    'Limit unnecessary use of a device and energy consumption',
                isListView: true,
                listItems: [
                    {
                        item: 'Shut down your devices when possible.',
                        icon: 'button',
                    },
                    {
                        item:
                            'Turn off GPS, Wifi, and Bluetooth functions on your phone or tablet when not in use, or set the device on "airplane" mode.',
                        icon: 'signal',
                    },
                    {
                        item:
                            'Bookmark frequently viewed web addresses and remember to close the internet pages once your search is complete.',
                        icon: 'star',
                    },
                ],
            },
        } as OutroBoxData,
    },
    [CardBucketIds.DEST_C]: {
        title: 'Networks',
        instruction: 'Drag here',
        cards: [],
        maxCards: 1,
        isFull: false,
        questions: [
            {
                text:
                    'In a teleconference, turning on the video emits %s more carbon than not using video.',
                answers: ['0%', '20%', '30%'],
                correctAnswer: '30%',
            },
            {
                text:
                    'Spending 10 minutes streaming an HD video on a smartphone is equivalent to using a 2,000W electric oven at full power for %s minutes.',
                answers: ['5', '30', '60'],
                correctAnswer: '5',
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'limit-weight',
            modalData: {
                subtitle: 'Networks',
                title: 'Limit the weight of shared content',
                isListView: true,
                listItems: [
                    {
                        item: 'Use Wifi instead of 4G on cell phones.',
                        icon: 'signal',
                    },
                    {
                        item:
                            'Lighten messages: compress the size of attachments; target recipients properly; create a light signature for internal exchanges and established contacts.',
                        icon: 'clip',
                    },
                    {
                        item:
                            'Adjust the video setting to "low definition" for vidio conference, webinars and private use.',
                        icon: 'screen',
                    },
                ],
            },
        } as OutroBoxData,
    },
    [CardBucketIds.DEST_D]: {
        title: 'Data centers',
        instruction: 'Drag here',
        cards: [],
        maxCards: 1,
        isFull: false,
        questions: [
            {
                text:
                    'Storing 1000 emails in the cloud for 5 years emits 2,75 kg CO2-eq, as much as driving %s km by car.',
                answers: ['1', '3', '8'],
                correctAnswer: '8',
                source: 'Quantis, Écobilan pour RTS (2020), Ecoinvent',
            },
            {
                text:
                    'At work, exchanging digital documents on a shared platform instead of sending them by email would decrease the associated carbon emissions up to %s.',
                answers: ['40%', '80%', '120%'],
                correctAnswer: '80%',
                source: 'The Shift Project, 2019',
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'data-storage',
            modalData: {
                subtitle: 'Data centers',
                title: 'Limit data storage and use a shared platform at work',
                isListView: true,
                listItems: [
                    {
                        item: 'Clean up your mailbox.',
                        icon: 'mailbox',
                    },
                    {
                        item:
                            'When working on a group project, use collaborative tools such as Teams. For mails, send links to files in the Cloud rather than as direct attachments.',
                        icon: 'link',
                    },
                    {
                        item: 'Clean up your files in the Cloud.',
                        icon: 'cloud',
                    },
                ],
            },
        } as OutroBoxData,
    },
} as CardBuckets;

export const correctCardBuckets = Object.keys(initCardBuckets).reduce(
    (acc, k) => {
        acc[k as CardBucketIds] = {
            ...initCardBuckets[k as CardBucketIds],
            cards: initCardSrc.filter((c) => {
                return c.allowedBuckets.includes(k as CardBucketIds);
            }),
        };
        return acc;
    },
    {} as CardBuckets,
);

export const correctCardSrcBuckets = cardBucketsWhere(correctCardBuckets, [
    CardBucketIds.SRC,
]);
export const correctCardDestBuckets = cardBucketsWhere(correctCardBuckets, [
    CardBucketIds.DEST_A,
    CardBucketIds.DEST_B,
    CardBucketIds.DEST_C,
    CardBucketIds.DEST_D,
]);

export const COIN_VALUE = 10;

export const initCoinSrc: CoinData[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
    (_, idx) => ({
        id: 'coin-' + idx,
        value: COIN_VALUE,
    }),
);

export const initCoinBuckets = {
    [CoinBucketIds.SRC]: {
        coins: initCoinSrc,
        maxCoins: 0,
        correctValue: 0,
        width: initCardBuckets[CardBucketIds.SRC].maxCards,
        associatedCardBucket: CardBucketIds.SRC,
        modalContent: {} as ModalData,
    },
    [CoinBucketIds.DEST_A]: {
        coins: [],
        maxCoins: 10,
        correctValue: 40,
        width: initCardBuckets[CardBucketIds.DEST_A].maxCards,
        associatedCardBucket: CardBucketIds.DEST_A,
        modalContent: {
            title: 'Device production',
            content:
                'Digital devices are made of numerous rare minerals and materials, assembled in countries where the energy mix often relies on fossil fuels. The amount of energy needed for their production increases as the level of miniaturization increases.',
        },
    },
    [CoinBucketIds.DEST_B]: {
        coins: [],
        maxCoins: 10,
        correctValue: 20,
        width: initCardBuckets[CardBucketIds.DEST_B].maxCards,
        associatedCardBucket: CardBucketIds.DEST_B,
        modalContent: {
            title: 'Device usage',
            content:
                'Our digital devices need electricity to operate. The carbon emissions from this electricity depends on the energy mix of the country where they are used. As digital devices become more sophisticated, the energy needed to power them, and therefore carbon intensity, tends to increase.',
        },
    },
    [CoinBucketIds.DEST_C]: {
        coins: [],
        maxCoins: 10,
        correctValue: 20,
        width: initCardBuckets[CardBucketIds.DEST_C].maxCards,
        associatedCardBucket: CardBucketIds.DEST_C,
        modalContent: {
            title: 'Networks',
            content:
                'The content we send and stream on digital networks relies on energy that must be available and updated continuously. This energy comes from both renewable and non-renewable sources, including gas, oil and coal.',
        },
    },
    [CoinBucketIds.DEST_D]: {
        coins: [],
        maxCoins: 10,
        correctValue: 20,
        width: initCardBuckets[CardBucketIds.DEST_D].maxCards,
        associatedCardBucket: CardBucketIds.DEST_D,
        modalContent: {
            title: 'Data centers',
            content:
                'Data centers need a lot of energy for IT equipment and cooling infrastructures. The more data we share, the more space and energy is needed to operate data centers, resulting in higher carbon emissions if the energy is sourced from fossil fuels.',
        },
    },
} as CoinBuckets;

// NO NEED TO CHANGE STUFF AFTER THIS.

// Helper function to create indexes & split
// original coin bucket data based on how many each bucket expects.
const correctCoinBucketIds = Object.keys(initCoinBuckets)
    .map((k) => initCoinBuckets[k as CoinBucketIds].correctValue)
    .map(cumulativeSum)
    .map((n) => Math.round(n / 10))
    .reduce((acc, cur, idx, arr) => {
        if (0 === idx) {
            acc.push([0, arr[idx]]);
        }
        if (idx < arr.length - 1) {
            acc.push(arr.slice(idx, idx + 2));
        }
        return acc;
    }, [] as number[][]);

// Applying the helper function above.
const correctCoinBucketData = correctCoinBucketIds.map((ids) => {
    const [a, b] = ids;
    return initCoinSrc.slice(a, b);
});

export const correctCoinBuckets = Object.keys(initCoinBuckets).reduce(
    (acc, k, idx) => {
        acc[k as CoinBucketIds] = {
            ...initCoinBuckets[k as CoinBucketIds],
            coins: correctCoinBucketData[idx],
        };
        return acc;
    },
    {} as CoinBuckets,
);

export const correctCoinDestBuckets = coinBucketsWhere(correctCoinBuckets, [
    CoinBucketIds.DEST_A,
    CoinBucketIds.DEST_B,
    CoinBucketIds.DEST_C,
    CoinBucketIds.DEST_D,
]);
