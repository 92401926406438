import AppString from '../../components/String';
import ActualStrings, { Strings } from '../../strings';
import React, { Dispatch, ReactElement } from 'react';
import Header from '../../components/Header';
import './style.scss';
import {
    AppAction,
    AppActions,
    CardBucketIds,
    CardBuckets,
} from '../../lib/types';
import { cardBucketsWhere } from '../../lib/helpers';

// Helper function.
const outroCards = (
    buckets: CardBuckets,
    dispatch: Dispatch<AppAction>,
): ReactElement => {
    return (
        <>
            {Object.keys(buckets).map((k) => {
                const bucket = buckets[k as CardBucketIds];
                return (
                    <div
                        key={k}
                        onClick={() =>
                            dispatch({
                                type: AppActions.MODAL_OPEN,
                                content: bucket.outroBox.modalData,
                            })
                        }
                        className="ViewOutro__box"
                    >
                        <div className="ViewOutro__box-inner">
                            <h2 className="ViewOutro__box-title">
                                {bucket.outroBox.modalData.title}
                            </h2>
                            <div>
                                <span
                                    role="button"
                                    className="ViewOutro__info-btn"
                                >
                                    i
                                </span>
                            </div>
                        </div>
                        <div
                            className={
                                'ViewOutro__box-spacer img-' +
                                bucket.outroBox.imgClassName
                            }
                        />
                    </div>
                );
            })}
        </>
    );
};

const ViewOutro = ({
    onHome,
    dispatch,
    cardBuckets,
}: {
    onHome: () => void;
    dispatch: Dispatch<AppAction>;
    cardBuckets: CardBuckets;
}): ReactElement => {
    const bucketsLeft = cardBucketsWhere(cardBuckets, [
        CardBucketIds.DEST_A,
        CardBucketIds.DEST_B,
    ]);
    const bucketsRight = cardBucketsWhere(cardBuckets, [
        CardBucketIds.DEST_C,
        CardBucketIds.DEST_D,
    ]);

    return (
        <div className="ViewOutro">
            <div className="container-vertical">
                <div className="container">
                    <Header title={Strings.OUTRO_TITLE} onHome={onHome} />
                    <div className="ViewOutro__intro-row row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString k={Strings.OUTRO_INTRO} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ViewOutro__box-row row">
                        <div className="col-6">
                            {outroCards(bucketsLeft, dispatch)}
                        </div>
                        <div className="col-6">
                            {outroCards(bucketsRight, dispatch)}
                        </div>
                    </div>
                </div>
                <div className="ViewOutro__button-wrapper">
                    <div className="ViewOutro__button">
                        <a
                            role="button"
                            className="Button"
                            href={ActualStrings[Strings.OUTRO_URL]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <AppString k={Strings.OUTRO_CTA} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewOutro;
