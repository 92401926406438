import AppString from '../../components/String';
import { Strings } from '../../strings';
import React, { ReactElement } from 'react';
import Header from '../../components/Header';
import './style.scss';

const ViewIdle = ({
    onNext,
    onHome,
}: {
    onNext: () => void;
    onHome: () => void;
}): ReactElement => {
    return (
        <div className="ViewIdle">
            <div className="container container-vertical">
                <Header onHome={onHome} />
                <div className="row">
                    <div className="col-6 ViewIdle__title-col">
                        <h1 className="ViewIdle__title">
                            <AppString k={Strings.IDLE_TITLE} />
                        </h1>
                        <div className="ViewIdle__img" />
                    </div>
                    <div className="col-6">
                        <p className="ViewIdle__intro">
                            <AppString k={Strings.IDLE_INTRO} />
                        </p>
                        <p className="ViewIdle__description">
                            <AppString k={Strings.IDLE_DESCRIPTION} />
                        </p>
                        <div className="ViewIdle__button">
                            <span
                                role="button"
                                className="Button Button--play"
                                onClick={onNext}
                            >
                                <AppString k={Strings.IDLE_CTA} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewIdle;
