import Modal from 'react-modal';
import React, { Dispatch, ReactElement } from 'react';
import { AppAction, AppActions, AppState } from '../../lib/types';
import './style.scss';

export const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40rem',
        height: '30rem',
        padding: '4rem 9rem 4rem 4rem',
        borderRadius: '0.5rem',
    },
};

const AppModal = ({
    state,
    dispatch,
}: {
    state: AppState;
    dispatch: Dispatch<AppAction>;
}): ReactElement => {
    const closeModal = () => dispatch({ type: AppActions.MODAL_CLOSE });

    return (
        <Modal
            isOpen={state.modalOpen}
            onRequestClose={closeModal}
            style={modalStyle}
            contentLabel="modal"
            closeTimeoutMS={300}
        >
            <span
                onClick={closeModal}
                className="AppModal__close-btn"
                role="button"
            >
                &times;
            </span>
            <div className="AppModal__inner">
                {state.modalContent.isListView ? (
                    <div className="AppModal__list-wrapper">
                        <div>
                            <h4 className="AppModal__list-subtitle text-bold">
                                {state.modalContent.subtitle}
                            </h4>
                            <h2 className="AppModal__list-title text-tilting-bold">
                                {state.modalContent.title}
                            </h2>
                        </div>
                        <ul className="AppModal__list">
                            {state.modalContent.listItems?.map((item, idx) => (
                                <li key={idx} className="AppModal__list-item">
                                    <span
                                        className={
                                            'AppModal__list-icon AppModal__list-icon-' +
                                            item.icon
                                        }
                                    />
                                    {item.item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <>
                        {state.modalContent.image && (
                            <div
                                className={
                                    'AppModal__icon icon icon-' +
                                    state.modalContent.image
                                }
                            />
                        )}
                        <h2 className="AppModal__title text-tilting-bold">
                            {state.modalContent.title}
                        </h2>
                        <p className="AppModal__content">
                            {state.modalContent.content}
                        </p>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AppModal;
