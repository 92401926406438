import AppString from '../../components/String';
import { Strings } from '../../strings';
import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    CardBuckets,
    CoinBuckets,
    GameState,
} from '../../lib/types';
import Header from '../../components/Header';
import GameControls from '../../components/GameControls';
import CardBucketStatic from '../../components/CardBucketStatic';
import CoinBucket from '../../components/CoinBucket';

const GameCoin = ({
    gameState,
    cardDestBuckets,
    coinSrcBuckets,
    coinDestBuckets,
    dispatch,
    onNext,
    onBack,
    onHome,
}: {
    gameState: GameState;
    cardDestBuckets: CardBuckets;
    coinSrcBuckets: CoinBuckets;
    coinDestBuckets: CoinBuckets;
    dispatch: Dispatch<AppAction>;
    onNext: () => void;
    onBack: () => void;
    onHome: () => void;
}): ReactElement => {
    return (
        <div className="Game">
            <div className="container-vertical">
                <div className="container">
                    <Header
                        title={Strings.GAME_DRAG_COIN_TITLE}
                        onHome={onHome}
                        dark={true}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString
                                        k={Strings.GAME_DRAG_COIN_DESCRIPTION}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Game__wrapper">
                    <div className="Game__src-wrapper">
                        <div className="container">
                            <div className="Game__container-inner">
                                <CoinBucket
                                    coinBuckets={coinSrcBuckets}
                                    isDragDisabled={
                                        gameState >= GameState.COINS_CHECKED
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Game__dest-wrapper--outer">
                        <div className="Game__dest-wrapper--coin">
                            <div className="container">
                                <CoinBucket
                                    isDragDisabled={
                                        gameState >= GameState.COINS_CHECKED
                                    }
                                    coinBuckets={coinDestBuckets}
                                />
                            </div>
                        </div>
                        <div className="Game__dest-wrapper">
                            <div className="container">
                                <div className="Game__container-inner">
                                    <CardBucketStatic
                                        cardBuckets={cardDestBuckets}
                                        dispatch={dispatch}
                                    />
                                    <GameControls
                                        gameState={gameState}
                                        dispatch={dispatch}
                                        onBack={onBack}
                                        onNext={onNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameCoin;
