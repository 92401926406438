import React, { ReactElement } from 'react';
import strings, { Strings } from '../../strings';

const AppString = ({ k }: { k: Strings }): ReactElement => {
    const out = strings[k].split('\n') || ['TODO'];
    return (
        <>
            {out.map((item, key) => {
                return (
                    <span key={key}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item,
                            }}
                        />
                        {out.length > 1 && <br />}
                    </span>
                );
            })}
        </>
    );
};

export default AppString;
