import { isMobile, isIPad13 } from 'react-device-detect';
import {
    CardBucketIds,
    CardBuckets,
    CoinBucketIds,
    CoinBuckets,
} from './types';

export const cssFixClasses = (initClasses = ''): string => {
    const fixClasses = [initClasses];
    if (isMobile) fixClasses.push('isMobile');
    if (isIPad13) fixClasses.push('iPad13');
    return fixClasses.join(' ');
};

// https://davidwells.io/snippets/disable-scrolling-with-javascript
export const noScroll = (): void => window.scrollTo(0, 0);

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const omniViewportHeight = (): void => {
    document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`,
    );
};

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffleArray = (array: unknown[]): unknown[] => {
    let currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const cumulativeSum = ((sum) => (value: number) => (sum += value))(0);

export const cardBucketsWhere = (
    cardBuckets: CardBuckets,
    keys: CardBucketIds[],
) => {
    return keys.reduce((acc, k) => {
        acc[k as CardBucketIds] = cardBuckets[k as CardBucketIds];
        return acc;
    }, {} as CardBuckets);
};

export const coinBucketsWhere = (
    coinBuckets: CoinBuckets,
    keys: CoinBucketIds[],
) => {
    return keys.reduce((acc, k) => {
        acc[k as CoinBucketIds] = coinBuckets[k as CoinBucketIds];
        return acc;
    }, {} as CoinBuckets);
};

export const areAllCardsMoved = (cardBuckets: CardBuckets): boolean => {
    const cardDestBuckets = cardBucketsWhere(cardBuckets, [
        CardBucketIds.DEST_A,
        CardBucketIds.DEST_B,
        CardBucketIds.DEST_C,
        CardBucketIds.DEST_D,
    ]);

    return Object.keys(cardDestBuckets).every(
        (k) => cardDestBuckets[k as CardBucketIds].isFull,
    );
};

export const areAllCoinsMoved = (coinBuckets: CoinBuckets): boolean => {
    return 0 === coinBuckets[CoinBucketIds.SRC].coins.length;
};
