import AppString from '../../components/String';
import { Strings } from '../../strings';
import CardBucket from '../../components/CardBucket';
import React, { Dispatch, ReactElement } from 'react';
import { AppAction, CardBuckets, GameState } from '../../lib/types';
import Header from '../../components/Header';
import GameControls from '../../components/GameControls';

const GameCard = ({
    gameState,
    cardSrcBuckets,
    cardDestBuckets,
    dispatch,
    onNext,
    onBack,
    onHome,
}: {
    gameState: GameState;
    cardSrcBuckets: CardBuckets;
    cardDestBuckets: CardBuckets;
    dispatch: Dispatch<AppAction>;
    onNext: () => void;
    onBack: () => void;
    onHome: () => void;
}): ReactElement => {
    return (
        <div className="Game">
            <div className="container-vertical">
                <div className="container">
                    <Header
                        title={Strings.GAME_DRAG_CARD_TITLE}
                        onHome={onHome}
                        dark={true}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString
                                        k={Strings.GAME_DRAG_CARD_DESCRIPTION}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Game__wrapper">
                    <div className="Game__src-wrapper">
                        <div className="container">
                            <div className="GameCard__container-inner">
                                <CardBucket
                                    cardBuckets={cardSrcBuckets}
                                    isDragDisabled={
                                        gameState >= GameState.CARDS_CHECKED
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Game__dest-wrapper">
                        <div className="container">
                            <div className="Game__container-inner">
                                <CardBucket
                                    cardBuckets={cardDestBuckets}
                                    isDragDisabled={
                                        gameState >= GameState.CARDS_CHECKED
                                    }
                                />
                                <GameControls
                                    gameState={gameState}
                                    dispatch={dispatch}
                                    onBack={onBack}
                                    onNext={onNext}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameCard;
