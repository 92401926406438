import React, { ReactElement } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { CardData } from '../../lib/types';
import './style.scss';

const Card = ({
    cardData,
    provided,
}: {
    cardData: CardData;
    provided: DraggableProvided;
}): ReactElement => {
    const innerClass =
        'Card__inner' +
        (undefined === cardData.correct
            ? ''
            : cardData.correct
            ? ' Card__inner--correct'
            : ' Card__inner--wrong');

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
            // style={getCardStyle(
            //     snapshot.isDragging,
            //     provided.draggableProps.style,
            // )}
            className="Card"
        >
            <div className={innerClass}>
                <div className={'Card__icon icon icon-' + cardData.id} />
                <h2 className="Card__caption">{cardData.content}</h2>
            </div>
        </div>
    );
};

export default Card;
