import React, { ReactElement } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { CoinData } from '../../lib/types';
import { getCoinStyle } from '../../lib/styling';
import './style.scss';

const Coin = ({
    coinData,
    provided,
    snapshot,
}: {
    coinData: CoinData;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}): ReactElement => {
    const coinClass =
        'Coin' +
        (undefined === coinData.correct
            ? ''
            : coinData.correct
            ? ' Coin--correct'
            : ' Coin--wrong');

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getCoinStyle(provided.draggableProps.style, snapshot)}
            className={coinClass}
        >
            <span className="Coin__value">{coinData.value}</span>
            <span className="Coin__percent">%</span>
        </div>
    );
};

export default Coin;
