import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    AppActions,
    CardBucketIds,
    CoinBucketIds,
    CoinBuckets,
    CoinData,
} from '../../lib/types';
import './style.scss';

const CoinBucketStatic = ({
    coinBuckets,
    dispatch,
    activeCardBucketId,
}: {
    coinBuckets: CoinBuckets;
    dispatch: Dispatch<AppAction>;
    activeCardBucketId?: CardBucketIds;
}): ReactElement => {
    return (
        <div className="CoinBucket__wrapper">
            {Object.keys(coinBuckets).map((k: string) => {
                const coinBucket = coinBuckets[k as CoinBucketIds];
                const coins = coinBucket.coins;
                const bucketClass =
                    'CoinBucketStatic__wrapper-single CoinBucket__wrapper-single' +
                    (undefined !== activeCardBucketId &&
                    coinBucket.associatedCardBucket !== activeCardBucketId
                        ? ' CoinBucketStatic__wrapper-single--inactive'
                        : '');

                return (
                    <div
                        key={k}
                        style={{
                            flexBasis: 1.25 + coinBucket.width * 5.65 + 'rem', // See Card/style.scss
                        }}
                        className={bucketClass}
                        onClick={() =>
                            dispatch({
                                type: AppActions.MODAL_OPEN,
                                content: coinBucket.modalContent,
                            })
                        }
                    >
                        <div className="CoinBucketStatic__inner-value">
                            <span>{coinBucket.correctValue}%</span>
                        </div>
                        <div className="CoinBucket__inner">
                            {coins.map((coinData: CoinData, idx: number) => (
                                <div key={idx} className="Coin">
                                    <span className="Coin__value">
                                        {coinData.value}
                                    </span>
                                    <span className="Coin__percent">%</span>
                                </div>
                            ))}
                            <span
                                role="button"
                                className="CoinBucketStatic__inner-button"
                            >
                                <span>i</span>
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CoinBucketStatic;
