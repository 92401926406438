import React, { ReactElement } from 'react';
import {
    Draggable,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
} from 'react-beautiful-dnd';
import {
    BucketTypes,
    CardBucketIds,
    CardBuckets,
    CardData,
} from '../../lib/types';
import Card from '../Card';
import './style.scss';
import { getCardBucketStyle } from '../../lib/styling';

const CardBucket = ({
    cardBuckets,
    isDragDisabled,
}: {
    cardBuckets: CardBuckets;
    isDragDisabled: boolean;
}): ReactElement => {
    return (
        <div className="CardBucket__wrapper">
            {Object.keys(cardBuckets).map((k: string) => {
                const bucket = cardBuckets[k as CardBucketIds];
                const cards = bucket.cards;
                const maxWidth = 1.25 + bucket.maxCards * 5.65 + 'rem'; // See Card/style.scss

                return (
                    <div
                        key={k}
                        style={{
                            flexBasis: maxWidth,
                        }}
                        className="CardBucket__wrapper-single"
                    >
                        {bucket.title && (
                            <div
                                style={{
                                    maxWidth: maxWidth,
                                }}
                            >
                                <h2 className="CardBucket__title">
                                    {bucket.title}
                                </h2>
                                <span className="CardBucket__title-decoration" />
                            </div>
                        )}
                        {bucket.instruction && (
                            <span className="CardBucket__instruction">
                                {bucket.instruction}
                            </span>
                        )}
                        <Droppable
                            key={k}
                            droppableId={k}
                            isDropDisabled={
                                bucket.maxCards > 0 &&
                                cards.length >= bucket.maxCards
                            }
                            renderClone={(
                                provided: DraggableProvided,
                                snapshot: DraggableStateSnapshot,
                                rubric: DraggableRubric,
                            ) => (
                                <Card
                                    cardData={cards[rubric.source.index]}
                                    key={cards[rubric.source.index].id}
                                    provided={provided}
                                    // snapshot={snapshot}
                                />
                            )}
                            direction="horizontal"
                            type={BucketTypes.CARD}
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        ref={provided.innerRef}
                                        style={getCardBucketStyle(
                                            snapshot.isDraggingOver,
                                            bucket.maxCards,
                                        )}
                                        {...provided.droppableProps}
                                        className={
                                            'CardBucket__inner CardBucket__' + k
                                        }
                                    >
                                        {cards.map(
                                            (
                                                cardData: CardData,
                                                index: number,
                                            ) => (
                                                <Draggable
                                                    key={cardData.id}
                                                    draggableId={cardData.id}
                                                    index={index}
                                                    isDragDisabled={
                                                        isDragDisabled
                                                    }
                                                >
                                                    {(provided) => (
                                                        <Card
                                                            cardData={cardData}
                                                            key={cardData.id}
                                                            provided={provided}
                                                            // snapshot={snapshot}
                                                        />
                                                    )}
                                                </Draggable>
                                            ),
                                        )}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                );
            })}
        </div>
    );
};

export default CardBucket;
