import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    CardBucketIds,
    CardBuckets,
    CardData,
} from '../../lib/types';
import './style.scss';
import CardStatic from '../CardStatic';

const CardBucketStatic = ({
    cardBuckets,
    dispatch,
}: {
    cardBuckets: CardBuckets;
    dispatch: Dispatch<AppAction>;
}): ReactElement => {
    return (
        <div className="CardBucket__wrapper CardBucketStatic">
            {Object.keys(cardBuckets).map((k: string) => {
                const bucket = cardBuckets[k as CardBucketIds];
                const cards = bucket.cards;
                const maxWidth = 1.25 + bucket.maxCards * 5.65 + 'rem'; // See Card/style.scss

                return (
                    <div
                        key={k}
                        style={{
                            flexBasis: maxWidth,
                        }}
                        className="CardBucket__wrapper-single"
                    >
                        {bucket.title && (
                            <div
                                style={{
                                    maxWidth: maxWidth,
                                }}
                            >
                                <h2 className="CardBucket__title">
                                    {bucket.title}
                                </h2>
                                <span className="CardBucket__title-decoration" />
                            </div>
                        )}
                        {bucket.instruction && (
                            <span className="CardBucket__instruction">
                                {bucket.instruction}
                            </span>
                        )}
                        <div className={'CardBucket__inner CardBucket__' + k}>
                            {cards.map((cardData: CardData) => {
                                return (
                                    <CardStatic
                                        cardData={cardData}
                                        key={cardData.id}
                                        dispatch={dispatch}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CardBucketStatic;
