export enum Strings {
    IDLE_TITLE,
    IDLE_INTRO,
    IDLE_DESCRIPTION,
    IDLE_CTA,
    INTRO_TITLE,
    INTRO_DESCRIPTION,
    INTRO_CTA,

    GAME_CONTROLS_CHECK,
    GAME_CONTROLS_NEXT,
    GAME_CONTROLS_FINISH,

    GAME_DRAG_CARD_TITLE,
    GAME_DRAG_CARD_DESCRIPTION,
    GAME_DRAG_CARD_DESCRIPTION_STATIC,
    GAME_DRAG_CARD_BEGIN,
    GAME_DRAG_CARD_MORE,
    GAME_DRAG_CARD_RESULT,
    GAME_DRAG_CARD_RESULT_COMMENT,

    GAME_DRAG_COIN_TITLE,
    GAME_DRAG_COIN_DESCRIPTION,
    GAME_DRAG_COIN_DESCRIPTION_STATIC,
    GAME_DRAG_COIN_BEGIN,
    GAME_DRAG_COIN_MORE,
    GAME_DRAG_COIN_RESULT,
    GAME_DRAG_COIN_RESULT_COMMENT,

    GAME_QUESTIONS_TITLE,
    GAME_QUESTIONS_DESCRIPTION,
    GAME_QUESTIONS_INSTRUCTION,
    GAME_QUESTIONS_RESULT,

    GAME_RECAP_TITLE,
    GAME_RECAP_DESCRIPTION,
    GAME_RECAP_NEXT,

    OUTRO_TITLE,
    OUTRO_INTRO,
    OUTRO_CTA,
    OUTRO_URL,
}

export default {
    // Idle screen

    [Strings.IDLE_TITLE]: 'Digital\nlife cycle',
    [Strings.IDLE_INTRO]:
        'How can we make more climate-friendly choices in our everyday life? We need to first understand where our impacts come from.',
    [Strings.IDLE_DESCRIPTION]:
        'In this game, we will explore the carbon footprint of all the digital activities we encounter in a typical work day. We use the lens of life cycle thinking, which is a framework that assesses the environmental impact of a product or service from production to usage and beyond.\nAre you ready to learn more about the carbon footprint of your digital world?',
    [Strings.IDLE_CTA]: 'PLAY',

    // Intro screen

    [Strings.INTRO_TITLE]: 'Our Digital World',
    [Strings.INTRO_DESCRIPTION]:
        'Take a look around you, you probably see a computer, a phone, a charger, a cable... But what about all the invisible components and activities that are needed to make our digital world work? To assess the carbon footprint of our digital work day, we need to organize all these components and activities into life cycle stages.\nHere you can see 4 of the main life cycle stages of a digital carbon footprint.',
    [Strings.INTRO_CTA]: 'Next',

    // Shared game controls

    [Strings.GAME_CONTROLS_CHECK]: 'CHECK',
    [Strings.GAME_CONTROLS_NEXT]: 'Next',
    [Strings.GAME_CONTROLS_FINISH]: 'Finish',

    // Game screen - cards

    [Strings.GAME_DRAG_CARD_TITLE]: '1. BUILD THE LIFE CYCLE STAGES',
    [Strings.GAME_DRAG_CARD_DESCRIPTION]:
        'Can you guess which of the activity cards are part of the 4 life cycle stages below? <strong>Place the cards which have a significant impact on the carbon footprint of digital devices.</strong>',
    [Strings.GAME_DRAG_CARD_DESCRIPTION_STATIC]:
        'Correct life cycle stages and activities',
    [Strings.GAME_DRAG_CARD_BEGIN]: 'Start dragging a card',
    [Strings.GAME_DRAG_CARD_MORE]: 'Fill all available spots',
    [Strings.GAME_DRAG_CARD_RESULT]:
        'The incorrect cards are marked in red.\nClick on NEXT to find out why',
    [Strings.GAME_DRAG_CARD_RESULT_COMMENT]:
        'Click on the cards to find out why they are correct or incorrect',

    // Game screen - coins

    [Strings.GAME_DRAG_COIN_TITLE]: '2. GUESS THE CARBON FOOTPRINT',
    [Strings.GAME_DRAG_COIN_DESCRIPTION]:
        'How much does each life cycle stage contribute to the total digital carbon footprint? <strong>Drag the percentage coins to the appropriate life cycle stages below</strong>.',
    [Strings.GAME_DRAG_COIN_DESCRIPTION_STATIC]:
        'Correct carbon footprint of the life cycle stages',
    [Strings.GAME_DRAG_COIN_BEGIN]: 'Start dragging a coin...',
    [Strings.GAME_DRAG_COIN_MORE]: 'Drag all available coins',
    [Strings.GAME_DRAG_COIN_RESULT]:
        'The incorrect responses are marked in red.\nClick on NEXT to see the correct carbon footprint.',
    [Strings.GAME_DRAG_COIN_RESULT_COMMENT]:
        'Ready to test your digital footprint knowledge further?\nClick on NEXT to learn more!',

    // Game screen - questions

    [Strings.GAME_QUESTIONS_TITLE]: '3. LEARN ABOUT THE IMPACTS',
    [Strings.GAME_QUESTIONS_DESCRIPTION]:
        'Test your knowledge on the relative carbon footprint of digital devices and services at each life cycle stage.',
    [Strings.GAME_QUESTIONS_INSTRUCTION]:
        'Click on the number you think is correct...',
    [Strings.GAME_QUESTIONS_RESULT]: 'Nice guess! Move to the next step',

    // Recap, a copy of game coin static
    [Strings.GAME_RECAP_TITLE]:
        'THE LIFE CYCLE CARBON IMPACT OF OUR DIGITAL WORLD',
    [Strings.GAME_RECAP_DESCRIPTION]: '',
    [Strings.GAME_RECAP_NEXT]: 'GO TO TIPS',

    // Outro

    [Strings.OUTRO_TITLE]: 'TIPS FOR REDUCING YOUR PERSONAL DIGITAL FOOTPRINT',
    [Strings.OUTRO_INTRO]:
        'We can all help to reduce the environmental impact of digital devices and services. Let’s do our part and spread the word, too! ',
    [Strings.OUTRO_CTA]: 'LEARN MORE',
    [Strings.OUTRO_URL]: 'https://www.quantis-intl.com',
};
