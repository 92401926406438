import React, { ReactElement, useReducer } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { initializeState, reducer } from './lib/store';
import {
    AppActions,
    BucketTypes,
    CardBucketIds,
    CoinBucketIds,
    GameState,
} from './lib/types';
import '@atlaskit/css-reset';
import './App.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import {
    cardBucketsWhere,
    coinBucketsWhere,
    cssFixClasses,
} from './lib/helpers';
import ViewIdle from './views/0_Idle';
import GameCard from './views/2_GameCard';
import {
    correctCardDestBuckets,
    correctCardSrcBuckets,
    correctCoinDestBuckets,
} from './data';
import GameCardStatic from './views/3_GameCardStatic';
import GameCoin from './views/4_GameCoin';
import GameCoinStatic from './views/5_GameCoinStatic';
import GameQuestions from './views/6_GameQuestions';
import ViewOutro from './views/7_Outro';
import AppModal from './components/Modal';

const App = (): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initializeState());

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;
        switch (result.type) {
            case BucketTypes.CARD:
                dispatch(
                    source.droppableId === destination.droppableId
                        ? {
                              type: AppActions.REORDER_CARDS,
                              bucketId: source.droppableId,
                              idxFrom: source.index,
                              idxTo: destination.index,
                          }
                        : {
                              type: AppActions.MOVE_CARD,
                              bucketIdFrom: source.droppableId,
                              bucketIdTo: destination.droppableId,
                              idxFrom: source.index,
                              idxTo: destination.index,
                          },
                );
                break;
            case BucketTypes.COIN:
                if (source.droppableId === destination.droppableId) return;
                dispatch({
                    type: AppActions.MOVE_COIN,
                    bucketIdFrom: source.droppableId,
                    bucketIdTo: destination.droppableId,
                    idxFrom: source.index,
                    idxTo: destination.index,
                });
                break;
        }
    };

    const reset = () => {
        state.swiper?.slideTo(0);
    };

    const nextSlide = () => {
        state.swiper?.slideNext();
    };

    const prevSlide = () => {
        state.swiper?.slidePrev();
    };

    const cardSrcBuckets = cardBucketsWhere(state.cardBuckets, [
        CardBucketIds.SRC,
    ]);
    const cardDestBuckets = cardBucketsWhere(state.cardBuckets, [
        CardBucketIds.DEST_A,
        CardBucketIds.DEST_B,
        CardBucketIds.DEST_C,
        CardBucketIds.DEST_D,
    ]);

    const coinSrcBuckets = coinBucketsWhere(state.coinBuckets, [
        CoinBucketIds.SRC,
    ]);
    const coinDestBuckets = coinBucketsWhere(state.coinBuckets, [
        CoinBucketIds.DEST_A,
        CoinBucketIds.DEST_B,
        CoinBucketIds.DEST_C,
        CoinBucketIds.DEST_D,
    ]);

    return (
        <div className={cssFixClasses('App')}>
            <div className="portrait-lock">
                <img src="assets/img/rotate_icon.png" alt="Rotate icon" />
                <span>
                    PLEASE USE A TABLET IN PORTRAIT MODE OR A DESKTOP DEVICE
                </span>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Swiper
                    allowTouchMove={false}
                    effect="fade"
                    onSwiper={(swiper) => {
                        dispatch({
                            type: AppActions.SET_SWIPER,
                            swiper: swiper,
                        });
                    }}
                >
                    <SwiperSlide key="s0">
                        <ViewIdle onNext={nextSlide} onHome={reset} />
                    </SwiperSlide>
                    {/*<SwiperSlide key="s1">*/}
                    {/*    <ViewIntro*/}
                    {/*        onNext={nextSlide}*/}
                    {/*        onHome={reset}*/}
                    {/*        cardBuckets={cardDestBuckets}*/}
                    {/*    />*/}
                    {/*</SwiperSlide>*/}
                    <SwiperSlide key="s2">
                        <GameCard
                            gameState={state.cardGameState}
                            cardSrcBuckets={cardSrcBuckets}
                            cardDestBuckets={cardDestBuckets}
                            dispatch={dispatch}
                            onNext={nextSlide}
                            onBack={prevSlide}
                            onHome={reset}
                        />
                    </SwiperSlide>
                    <SwiperSlide key="s3">
                        <GameCardStatic
                            gameState={GameState.CARDS_DONE}
                            cardSrcBuckets={correctCardSrcBuckets}
                            cardDestBuckets={correctCardDestBuckets}
                            dispatch={dispatch}
                            onNext={nextSlide}
                            onBack={prevSlide}
                            onHome={reset}
                        />
                    </SwiperSlide>
                    <SwiperSlide key="s4">
                        <GameCoin
                            gameState={state.coinGameState}
                            cardDestBuckets={correctCardDestBuckets}
                            coinSrcBuckets={coinSrcBuckets}
                            coinDestBuckets={coinDestBuckets}
                            dispatch={dispatch}
                            onNext={nextSlide}
                            onBack={prevSlide}
                            onHome={reset}
                        />
                    </SwiperSlide>
                    <SwiperSlide key="s5">
                        <GameCoinStatic
                            gameState={GameState.COINS_DONE}
                            cardDestBuckets={correctCardDestBuckets}
                            coinDestBuckets={correctCoinDestBuckets}
                            dispatch={dispatch}
                            onNext={nextSlide}
                            onBack={prevSlide}
                            onHome={reset}
                        />
                    </SwiperSlide>
                    {[
                        CardBucketIds.DEST_A,
                        CardBucketIds.DEST_B,
                        CardBucketIds.DEST_C,
                        CardBucketIds.DEST_D,
                    ].map((bucketId) => {
                        return (
                            <SwiperSlide key={bucketId}>
                                <GameQuestions
                                    activeCardBucketId={bucketId}
                                    cardDestBuckets={cardDestBuckets}
                                    coinDestBuckets={correctCoinDestBuckets}
                                    dispatch={dispatch}
                                    onNext={nextSlide}
                                    onBack={prevSlide}
                                    onHome={reset}
                                />
                            </SwiperSlide>
                        );
                    })}
                    <SwiperSlide key="s7">
                        <ViewOutro
                            dispatch={dispatch}
                            onHome={reset}
                            cardBuckets={cardDestBuckets}
                        />
                    </SwiperSlide>
                </Swiper>

                <div className="btn-debug">
                    <button onClick={prevSlide}>{'<<'}</button>
                    <button onClick={nextSlide}>{'>>'}</button>
                </div>

                <AppModal state={state} dispatch={dispatch} />
            </DragDropContext>
        </div>
    );
};

export default App;
